import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
interface PetDocumentProps {
  name?: string
}
export const PetDocument: React.FC<PetDocumentProps> = ({ name }) => {
  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });
  console.log(name)
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>Section #1</Text>
          </View>
          <View style={styles.section}>
            <Text>Section #2 hello, {name}</Text>
            <Text>{name}</Text>
          </View>
        </Page>
      </Document>
    </>
  )
}
