import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";

import "@pankod/refine-antd/dist/reset.css";
import routerProvider from "@pankod/refine-react-router-v6";
import resources from "./resources";
import { API_URL } from "consts";
import { DataProvider } from "./dataprovider";
import { authProvider } from "./authProvider";
import { AuthPage } from "pages/auth";
import { accessProvider } from "accessProvider";
import { Header } from "./components/header";
import { useTranslation } from "react-i18next";
import { DashboardPage } from "pages/dashboard";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      ReadyPage={ReadyPage}
      DashboardPage={DashboardPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            path: "/register",
            element: <AuthPage type="register" />,
          },
          {
            path: "/forgot-password",
            element: <AuthPage type="forgotPassword" />,
          },
          {
            path: "/update-password",
            element: <AuthPage type="updatePassword" />,
          },
        ],
      }}
      i18nProvider={i18nProvider}
      dataProvider={DataProvider(API_URL)}
      authProvider={authProvider}
      accessControlProvider={accessProvider}
      Header={Header}
      LoginPage={() => <AuthPage type="login" />}
      resources={resources}
    />
  );
}

export default App;
