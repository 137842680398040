import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useTranslate,
} from "@pankod/refine-core";

import {
  Menu,
  Icons,
  Dropdown,
  Input,
  Avatar,
  Typography,
  Space,
  Grid,
  Row,
  Col,
  AutoComplete,
  AntdLayout,
} from "@pankod/refine-antd";

import { useTranslation } from "react-i18next";

const { Header: AntdHeader } = AntdLayout;
const { SearchOutlined, DownOutlined } = Icons;
const { Text } = Typography;
const { useBreakpoint } = Grid;



export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const t = useTranslate();

  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages ?? [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar
                size={16}
                src={`/images/flags/${lang}.svg`}
              />
            </span>
          }
        >
          {lang === "en" ? "English" : "German"}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdHeader
      style={{
        padding: "0 24px",
        background: "white",
      }}
    >
      <Row
        align="middle"
        style={{
          justifyContent: screens.sm ? "space-between" : "end",
        }}
      >
        <Col xs={0} sm={12}>
          <AutoComplete
            style={{
              width: "100%",
              maxWidth: "550px",
            }}
            filterOption={false}
          >
            <Input
              size="large"
              placeholder={t("search.placeholder")}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Col>
        <Col>
          <Space size="middle" align="center">
            <Dropdown overlay={menu}>
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
              }<a
                style={{ color: "inherit" }}
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  <Avatar
                    size={16}
                    src={`/images/flags/${currentLocale}.svg`}
                  />
                  <div
                    style={{
                      display: screens.lg
                        ? "block"
                        : "none",
                    }}
                  >
                    English
                    <DownOutlined
                      style={{
                        fontSize: "12px",
                        marginLeft: "6px",
                      }}
                    />
                  </div>
                </Space>
              </a>
            </Dropdown>

            <Text
              ellipsis
              strong
              style={{
                display: "flex",
              }}
            >
              {user?.first_name + " " + user?.last_name}
            </Text>
            <Avatar
              size="large"
              src="https://i.pravatar.cc/150"
              alt={user?.name}
            />
          </Space>
        </Col>
      </Row>
    </AntdHeader>
  );
};
