import {
  Form,
  FormItemProps,
  Icons,
  Upload,
  UploadProps,
} from "@pankod/refine-antd";
import { API_URL, TOKEN_KEY } from "consts";
import { getUploadValueFromEvent, getUploadValueProps } from "utils/goapi";

interface UploaderProps {
  name: string;
  upProps?: UploadProps;
  children?: React.ReactNode;
  formItemProps?: FormItemProps;
}
export const Uploader: React.FC<UploaderProps> = ({
  name,
  upProps,
  children,
  formItemProps,
}) => {
  const listType = () => {
    let t = upProps?.listType;
    if (!t) {
      if (upProps?.multiple) {
        t = "picture";
      } else {
        t = "picture-card";
      }
    }
    return t;
  };
  const uploadContent = () => {
    if (children) {
      return children;
    }
    if (!upProps?.multiple) {
      return (
        <div>
          <Icons.PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    }
    return (
      <>
        <p className="ant-upload-drag-icon">
          <Icons.InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading
          company data or other band files
        </p>
      </>
    );
  };
  const uploadNode = (children: React.ReactNode) => {
    const props = {
      accept: "image/*",
      name: "files",
      action: `${API_URL}/upload-files`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
      listType: listType(),
      ...upProps,
    };
    if (!upProps?.multiple) {
      return (
        <Upload {...props} maxCount={1}>
          {children}
        </Upload>
      );
    }
    return <Upload.Dragger {...props}>{children}</Upload.Dragger>;
  };
  return (
    <>
      <Form.Item
        name={name}
        valuePropName="fileList"
        getValueProps={(data) => getUploadValueProps(data, API_URL)}
        getValueFromEvent={(args: any) =>
          getUploadValueFromEvent(args, upProps?.multiple)
        }
        noStyle
        {...formItemProps}
      >
        {uploadNode(uploadContent())}
      </Form.Item>
    </>
  );
};
