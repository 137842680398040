import { Edit, Form, Input, useForm } from "@pankod/refine-antd";
import { Uploader } from "components/uploader";

export const AuthorsEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    metaData: {
      fields: ["avatar"],
    },
  });
  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="desc">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Avatar" name="avatar">
          <Uploader name="avatar" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
