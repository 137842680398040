import {
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import slugify from "@sindresorhus/slugify";
import { Uploader } from "components/uploader";
import dayjs from "dayjs";
import { TinyMCE } from "components/form";

export const BlogsCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm();
  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues["title"]) {
      const slug = slugify(changedValues["title"], { separator: "-" });
      form.setFieldsValue({ slug: slug });
    }
  };
  const { selectProps: catSelectProps } = useSelect({
    resource: "blog-categories",
    optionLabel: "name",
    optionValue: "id",
  });
  const { selectProps: authorSelectProps } = useSelect({
    resource: "authors",
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row gutter={16}>
          <Col xl={12} md={24}>
            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} md={24}>
            <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={6} md={24}>
            <Form.Item
              label="Categories"
              name="categories"
              rules={[{ required: true }]}
              getValueProps={(values?: { id: number }[]) => {
                return { value: values?.map((c) => c.id) };
              }}
              getValueFromEvent={(args: any[]) => {
                return args.map((item) => ({
                  id: item,
                }));
              }}
            >
              <Select mode="multiple" {...catSelectProps} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Author"
              name="author"
              rules={[{ required: true }]}
              getValueProps={(value) => {
                return { value: value?.id };
              }}
              getValueFromEvent={(args: any) => {
                return args ? { id: args } : null;
              }}
            >
              <Select {...authorSelectProps} />
            </Form.Item>
          </Col>
          <Col xl={6} md={24}>
            <Form.Item
              label="Published"
              name="published"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xl={6} md={24}>
            <Form.Item
              label="PublishedAt"
              name="published_at"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Estimated Reading Time"
          name="estimated_reading_time"
          initialValue={5}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Thumb">
          <Uploader name="thumb" />
        </Form.Item>

        <Form.Item label="Excerpt" name="excerpt">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="ContentHtml" name="content_html">
          <TinyMCE />
          {/* <AntdCKEditor /> */}
        </Form.Item>
      </Form>
    </Create>
  );
};
