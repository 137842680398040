import axios from "axios";
import { ILoginResponse, IUser } from "types/user";

export const AuthHelper = (apiUrl: string) => ({
  login: async (identifier: string, password: string) => {
    const url = `${apiUrl}/auth/login`;
    return await axios.post<ILoginResponse>(url, {
      identifier,
      password,
    });
  },
  me: async (token: string) => {
    return await axios.get<IUser>(`${apiUrl}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
});
