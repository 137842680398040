import { Tag } from "@pankod/refine-antd"

interface StatusTagProps {
  value: boolean
}
const getStatusLable = (v: boolean): string => {
  return v ? 'Enable' : 'Disable'
};
export const StatusTag: React.FC<StatusTagProps> = ({ value }) => {
  return (
    <Tag color={value ? 'green' : 'red'}>{getStatusLable(value)}</Tag>
  )
}
