import { Col, Create, DatePicker, Form, Input, InputNumber, Row, useForm } from "@pankod/refine-antd"
import { Uploader } from "components/uploader"

export const PetMedicalsCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Pet" name="pet_id">
          <InputNumber />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Time" name="time">
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Place" name="place">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Notes" name="notes">
          <Input />
        </Form.Item>
        <Form.Item label="Attachments">
          <Uploader name="attachments" upProps={{ multiple: true }} />
        </Form.Item>
      </Form>
    </Create>
  )
}
