import { Create, Form, Input, useForm } from "@pankod/refine-antd";
import slugify from "@sindresorhus/slugify";
import { Uploader } from "components/uploader";

export const AuthorsCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm();
  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues["name"]) {
      const slug = slugify(changedValues["name"], { separator: "-" });
      form.setFieldsValue({ slug: slug });
    }
  };
  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="desc">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Avatar" name="avatar">
          <Uploader name="avatar" />
        </Form.Item>
      </Form>
    </Create>
  );
};
