import { Create, Form, Input, TreeSelect, useForm } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { buildTreeData } from "utils/utils";

export const BackendMenusCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm();
  const menus = useList({
    resource: "backend-menus",
    metaData: {
      queries: {
        tree: true,
      },
    },
    config: {
      pagination: { pageSize: 5000 },
    },
  });
  const menusTree: any[] = [
    { title: "Root", value: 0, children: buildTreeData(menus?.data?.data) },
  ];
  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Pid" name="pid">
          <TreeSelect treeData={menusTree} />
        </Form.Item>
        <Form.Item label="Path" name="path">
          <Input />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
