import { TextField } from "@pankod/refine-antd"
import { useOne } from "@pankod/refine-core"

export const useDictionary = (name: string) => {
  const { data } = useOne({
    resource: 'dictionaries/name',
    id: name,
  })
  const dic = data?.data
  let details: any = []
  const res = { data: details }
  if (!dic?.status) {
    return res
  }
  details = data?.data?.details.filter((detail: any) => detail.status)
  if (details) {
    details = details.map((detail: any) => {
      switch (dic?.type) {
        case 'integer':
          detail.value = parseInt(detail.value)
          break
        case 'bool':
          detail.value = detail.value === 'true'
      }
      return detail
    })
    details.sort((a: any, b: any) => {
      return b.sort - a.sort
    })
    res.data = details
  }

  return res
}

export const useDicRender = (name: string) => {
  const { data } = useDictionary(name)
  return {
    render: (value: any) => {
      const detail = data?.find((item: any) => item.value === value)
      return (<TextField value={detail ? detail.label : value} />)
    }
  }
}
