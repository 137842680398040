import { DeleteButton, EditButton, List, Space, Table, useTable } from "@pankod/refine-antd"

export const BackendUserRolesList: React.FC = () => {
    const { tableProps } = useTable({})
    return (
        <>
            <List>
                <Table {...tableProps} rowKey="id">
                    <Table.Column dataIndex="id" title="ID" />
                    <Table.Column dataIndex="name" title="Name" />
                    <Table.Column dataIndex="key" title="Key" />
                    <Table.Column dataIndex="created_at" title="CreatedAt" />
                    <Table.Column dataIndex="updated_at" title="UpdatedAt" />
                    <Table.Column
                        title="Actions"
                        dataIndex="actions"
                        render={(_text, record: any): React.ReactNode => {
                            return (
                                <Space>
                                    <EditButton
                                        size="small"
                                        hideText
                                        recordItemId={record.id}
                                    />
                                    <DeleteButton
                                        size="small"
                                        recordItemId={record.id}
                                        hideText
                                    />
                                </Space>
                            );
                        }
                        }
                    />
                </Table>
            </List>
        </>
    )
}