import { Col, Edit, Form, Input, InputNumber, Row, Select, Switch, useForm } from "@pankod/refine-antd"
import { RelationForm } from "components/relationForm/RelationForm"
import { StatusTag } from "components/tag/StatusTag"
import { typeOptions } from "./options"

export const DictionariesEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading, id } = useForm({
    metaData: {
      fields: ['details']
    }
  })
  const detailsColumns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
    },
    {
      title: 'Label',
      dataIndex: 'label',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'status',
      dataIndex: 'status',
      render: (value: any, record: any) => {
        return <StatusTag value={value} />
      }
    },
  ]
  const formItemNodes = (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Value" name="value">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Label" name="label">
            <Input />
          </Form.Item>

        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Sort" name="sort">
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Status" name="status" valuePropName="checked" initialValue={true}>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Desc" name="desc">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Type" name="type">
              <Select options={typeOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status" valuePropName="checked" initialValue={true}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Details"
          name="details"
          getValueProps={(value: any) => {
            if (value) {
              value.sort((a: any, b: any) => {
                return b.sort - a.sort
              })
            }
            return { value }
          }}
        >
          <RelationForm
            relationID={{ dictionary_id: parseInt(id as string) }}
            resource="dictionary-details"
            columns={detailsColumns}
            title="Details"
            createFormItemsNode={formItemNodes}
            editFormItemsNode={formItemNodes} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
