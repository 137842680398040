import { AuthProvider } from "@pankod/refine-core";
import { notification } from "@pankod/refine-antd";

import { TOKEN_KEY, API_URL, USER_KEY, MENU_KEY } from "./consts";

import { AuthHelper } from "utils/authHelper";

const strapiAuthHelper = AuthHelper(API_URL);
let menusCache: any = null;

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data, status } = await strapiAuthHelper.login(email, password);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.data.jwt);
        localStorage.setItem(USER_KEY, JSON.stringify(data.data.user)); //TODO: Need encrypt
        localStorage.setItem(MENU_KEY, JSON.stringify(data.data.user?.menus));
        return Promise.resolve();
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return Promise.reject({
          name: "Login Error",
          message: error.response.data.message,
        });
      } else {
        throw error;
      }
    }
    return Promise.reject();
  },
  register: async ({ email, password }) => {
    try {
      await authProvider.login({ email, password });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  updatePassword: async () => {
    notification.success({
      message: "Updated Password",
      description: "Password updated successfully",
    });
    return Promise.resolve();
  },
  forgotPassword: async ({ email }) => {
    notification.success({
      message: "Reset Password",
      description: `Reset password link sent to "${email}"`,
    });
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(MENU_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: async () => {
    if (menusCache) {
      return Promise.resolve(menusCache);
    }
    const menus = localStorage.getItem(MENU_KEY);
    return Promise.resolve(menus);
  },
  getUserIdentity: async () => {
    const us = localStorage.getItem(USER_KEY);
    if (!us) {
      return Promise.reject();
    }
    const user = JSON.parse(us);
    return Promise.resolve({
      ...user,
    });
  },
};
