import {
  BackendUsersList,
  BackendUsersCreate,
  BackendUsersEdit,
} from "pages/backend-users";
import {
  BackendApisList,
  BackendApisCreate,
  BackendApisEdit,
} from "pages/backend-apis";
import {
  BackendUserRolesList,
  BackendUserRolesCreate,
  BackendUserRolesEdit,
} from "pages/backend-user-roles";
import {
  BackendMenusList,
  BackendMenusCreate,
  BackendMenusEdit,
} from "pages/backend-menus";
import { Icons } from "@pankod/refine-antd";
import {
  UploadFilesList,
  UploadFilesCreate,
  UploadFilesEdit,
} from "pages/upload-files";

const backendResources = [
  {
    name: "backend",
    icon: <Icons.ShopOutlined />
  },
  {
    parentName: "backend",
    name: "backend-users",
    options: {
      label: "Users",
    },
    list: BackendUsersList,
    create: BackendUsersCreate,
    edit: BackendUsersEdit,
    icon: <Icons.UserOutlined />
  },
  {
    parentName: "backend",
    name: "backend-user-roles",
    options: {
      label: "Roles",
    },
    list: BackendUserRolesList,
    create: BackendUserRolesCreate,
    edit: BackendUserRolesEdit,
    icon: <Icons.TeamOutlined />
  },
  {
    parentName: "backend",
    name: "backend-menus",
    options: {
      label: "Menus",
    },
    list: BackendMenusList,
    create: BackendMenusCreate,
    edit: BackendMenusEdit,
    icon: <Icons.MenuOutlined />
  },
  {
    parentName: "backend",
    name: "backend-apis",
    options: {
      label: "APIs",
    },
    list: BackendApisList,
    create: BackendApisCreate,
    edit: BackendApisEdit,
    icon: <Icons.ApiOutlined />
  },
  {
    name: "upload-files",
    options: {
      label: "Media"
    },
    list: UploadFilesList,
    create: UploadFilesCreate,
    edit: UploadFilesEdit,
    icon: <Icons.FileImageOutlined />
  },
];
export default backendResources;
