import { Button, Icons, Space, Table, useModalForm } from "@pankod/refine-antd"
import { useDelete } from "@pankod/refine-core"
import { RelationFormModal } from "./RelationFormModal"

interface RelationFormProps {
  relationID: any
  title?: string
  resource: string
  value?: any[]
  columns?: { title: string, dataIndex: string, render?: (value: any, record: any, index: number) => React.ReactNode }[]
  columnsNode?: React.ReactNode
  createFormItemsNode?: React.ReactNode
  editFormItemsNode?: React.ReactNode
  onChange?: (value: any) => void;
}
export const RelationForm: React.FC<RelationFormProps> = ({ relationID, title, resource, value, columns, columnsNode, onChange, createFormItemsNode, editFormItemsNode }) => {
  const tableColumn = (): React.ReactNode => {
    if (columnsNode) {
      return columnsNode
    }
    if (columns) {
      return columns.map((col) => {
        return (
          <Table.Column title={col.title} dataIndex={col.dataIndex} render={col.render} />
        )
      })
    }
    return null
  }

  const { modalProps: addModalProps, formProps: addFormProps, show: showAddModal } = useModalForm({
    resource: resource,
    action: 'create',
    redirect: false,
    onMutationSuccess(data, variables, context) {
      const res = data?.data?.data
      if (res) {
        value?.push(res)
        onChange?.(value?.slice())
      }
    },
  })
  const { modalProps: editModalProps, formProps: editFormProps, show: showEditModal } = useModalForm({
    resource: resource,
    action: 'edit',
    redirect: false,
    warnWhenUnsavedChanges: true,
    onMutationSuccess(data, variables, context) {
      const res = data?.data?.data
      if (res) {
        const list = value?.map((item: any) => {
          if (item.id === res.id) {
            return res
          }
          return item
        })
        onChange?.(list)
      }
    },
  })
  const { mutate } = useDelete()

  const onAddBtn = () => {
    showAddModal()
  }
  const onEditBtn = (id: any) => {
    showEditModal(id)
  }
  const onDeleteBtn = (id: any) => {
    mutate(
      {
        resource: resource,
        id: id,
      },
      {
        onSuccess(data, variables, context) {
          const list = value?.filter((item: any) => item.id !== id)
          onChange?.(list)
        },
      }
    )
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'start', marginBottom: "1rem" }}>
        <Button
          icon={<Icons.PlusSquareOutlined />}
          onClick={() => onAddBtn()}
        >
          Add
        </Button>
      </div>
      <Table dataSource={value} rowKey="id" pagination={false}>
        {tableColumn()}
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_text, record: any): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  icon={<Icons.EditOutlined />}
                  onClick={() => onEditBtn(record.id)}
                />
                <Button
                  danger
                  size="small"
                  icon={<Icons.DeleteOutlined />}
                  onClick={() => onDeleteBtn(record.id)}
                />
              </Space>
            );
          }
          }
        />
      </Table>
      <RelationFormModal
        modalProps={{ ...addModalProps, title: `Create ${title}` }}
        formProps={
          {
            ...addFormProps,
            onFinish: (values) => {
              return addFormProps.onFinish?.({ ...values, ...relationID })
            }
          }
        }
        formItemNodes={createFormItemsNode} />
      <RelationFormModal
        modalProps={{ ...editModalProps, title: `Edit ${title}` }}
        formProps={
          {
            ...editFormProps,
            onFinish: (values) => {
              return editFormProps.onFinish?.({ ...values, ...relationID })
            }
          }
        }
        formItemNodes={editFormItemsNode} />
    </>
  )
}
