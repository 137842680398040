import { Button, DateField, DeleteButton, EditButton, Icons, List, Space, Table, useTable } from "@pankod/refine-antd"
import { pdf } from "@react-pdf/renderer"
import { ListThumb } from "components/list/ListThumb"
import { PetDocument } from "components/pdf"
import { useDicRender } from "hooks/dictionary"
import { useState } from "react"

export const PetsList: React.FC = () => {
  const { tableProps } = useTable({
    metaData: {
      fields: ['avatar']
    }
  })
  const { render: setOptionRender } = useDicRender('Sex')
  const [pdfBtnLoading, setPdfBtnLoading] = useState(false)
  const pdfClick = async () => {
    setPdfBtnLoading(true)
    const blob = await pdf(<PetDocument name="Lucy from code" />).toBlob()

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'pet.pdf';
    // Trigger the download
    link.click();
    // Clean up the URL after downloading
    URL.revokeObjectURL(url);
    setPdfBtnLoading(false)
  }
  return (
    <>
      <List
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="avatar" title="Avatar" render={(value) => {
            return <ListThumb value={value} />
          }} />
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column dataIndex="breed" title="Breed" />
          <Table.Column dataIndex="birthday" title="Birthday"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column dataIndex="sex" title="Sex" render={(value: any, record: any) => {
            return setOptionRender(value)
          }} />
          <Table.Column dataIndex="user_id" title="UserId" />
          <Table.Column
            dataIndex="created_at"
            title="CreatedAt"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column
            dataIndex="updated_at"
            title="UpdatedAt"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                  <Button
                    icon={<Icons.FilePdfOutlined />}
                    onClick={pdfClick}
                    loading={pdfBtnLoading}
                    size="small"
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  )
}
