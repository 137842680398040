import { Create, DatePicker, Form, Input, InputNumber, Select, useForm } from "@pankod/refine-antd"
import { Uploader } from "components/uploader"
import { useDictionary } from "hooks/dictionary"

export const PetsCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  const { data: sexOptions } = useDictionary('Sex')
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        form={form}
      >
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Breed" name="breed">
          <Input />
        </Form.Item>
        <Form.Item label="Birthday" name="birthday">
          <DatePicker />
        </Form.Item>
        <Form.Item label="Sex" name="sex">
          <Select options={sexOptions} />
        </Form.Item>
        <Form.Item label="ChipId" name="chip_id">
          <Input />
        </Form.Item>
        <Form.Item label="UserId" name="user_id">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Avatar" >
          <Uploader name="avatar" />
        </Form.Item>
      </Form>
    </Create>
  )
}
