import {
  Button,
  Card,
  Form,
  FormInstance,
  Icons,
  Input,
  InputNumber,
} from "@pankod/refine-antd";
import { UploadURLValue } from "components/uploader";
import { AssociateResult } from "./AssociateResult";

interface OptionItemProps {
  form?: FormInstance;
  removeFunc?: any;
  keyName?: any;
}
export const OptionItem: React.FC<OptionItemProps> = ({
  form,
  keyName,
  removeFunc,
}) => {
  const results = Form.useWatch("results", form);
  return (
    <Card
      title={`Answer of ${keyName + 1}`}
      style={{
        marginBottom: "1rem",
      }}
      extra={
        <Button
          danger
          onClick={() => removeFunc?.(keyName)}
          icon={<Icons.DeleteOutlined />}
        ></Button>
      }
    >
      <Form.Item hidden name={[keyName, "id"]} initialValue={keyName}>
        <Input />
      </Form.Item>
      <Form.Item label="Title" name={[keyName, "title"]}>
        <Input />
      </Form.Item>
      <Form.Item label="Image" name={[keyName, "img"]}>
        <UploadURLValue />
      </Form.Item>
      <Form.Item label="Score" name={[keyName, "score"]} initialValue={0}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Associate with" name={[keyName, "result_rules"]}>
        <AssociateResult results={results} />
      </Form.Item>
    </Card>
  );
};
