import { Row, Col } from "@pankod/refine-antd";

export const DashboardPage: React.FC = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col md={24}>Welcome</Col>
      <Col md={24}>Todo: add statistics</Col>
    </Row>
  );
};
