export const QuizTypes = [
  { label: "Personality", value: 2 },
  { label: "Score Personality", value: 21 },
  { label: "Score Quiz", value: 1 },
];

export const getQuizTypeLabel = (value: number) => {
  const t = QuizTypes.find((q) => q.value === value);
  return t?.label;
};

export const QuizQuestionTypes = [
  { label: "Multiple Choise", value: 1 },
  { label: "Multiple Choise With Image", value: 11 },
  { label: "Score Choise", value: 2 },
];
