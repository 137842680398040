import { Create, Form, Input, useForm } from "@pankod/refine-antd";
import slugify from "@sindresorhus/slugify";

export const QuizCategoriesCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm();
  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues["label"]) {
      const slug = slugify(changedValues["label"], { separator: "-" });
      form.setFieldsValue({ slug: slug });
    }
  };
  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
      >
        <Form.Item label="Label" name="label">
          <Input />
        </Form.Item>
        <Form.Item label="Slug" name="slug">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
