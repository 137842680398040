import { Icons, Upload, UploadProps, UploadFile } from "@pankod/refine-antd";
import { API_URL, TOKEN_KEY } from "../../consts";
import type { UploadChangeParam } from "antd/es/upload";
import { getThumbUrl } from "utils/goapi";
import { useEffect, useState } from "react";

interface UploadURLValueProps {
  children?: React.ReactNode;
  upProps?: UploadProps;
  value?: any;
  onChange?: any;
}
export const UploadURLValue: React.FC<UploadURLValueProps> = ({
  children,
  upProps,
  value,
  onChange,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    if (typeof value === "string") {
      if (value) {
        setFileList([
          {
            uid: value,
            name: value,
            url: getThumbUrl(value),
          },
        ]);
      } else {
        setFileList([]);
      }
    } else {
      setFileList(value);
    }
  }, [value]);
  const uploadButton = () => {
    if (children) {
      return children;
    }
    return (
      <div>
        <Icons.PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
  };
  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "removed") {
      onChange?.("");
    } else if (info.file.status === "done") {
      const file = info.file;
      if (file && file.response && file.response.data) {
        const f = file.response.data;
        onChange?.(f.key);
      }
    } else {
      onChange?.([...info.fileList]);
    }
  };

  return (
    <>
      <Upload
        accept="image/*"
        name="files"
        fileList={fileList}
        action={`${API_URL}/upload-files`}
        headers={{
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        }}
        listType="picture-card"
        onChange={handleChange}
        {...upProps}
      >
        {(!fileList || fileList.length <= 0) && uploadButton()}
      </Upload>
    </>
  );
};
