import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Icons,
  Input,
  Row,
  Select,
} from "@pankod/refine-antd";
import { UploadURLValue } from "components/uploader";
import { QuizQuestionTypes } from "data/quizType";
import { OptionItem } from "./OptionItem";

interface QuestionItemProps {
  form?: FormInstance;
  removeFunc?: any;
  keyName?: any;
}
export const QuestionItem: React.FC<QuestionItemProps> = ({
  form,
  keyName,
  removeFunc,
}) => {
  return (
    <Card
      style={{
        marginBottom: "2rem",
      }}
      title={`Question of ${keyName + 1}`}
      extra={
        <Button
          danger
          onClick={() => removeFunc?.(keyName)}
          icon={<Icons.CloseOutlined />}
        ></Button>
      }
    >
      <Form.Item
        hidden
        name={[keyName, "id"]}
        rules={[{ required: true }]}
        label="ID"
        initialValue={keyName}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name={[keyName, "questionType"]}
        rules={[{ required: true }]}
        label="Type"
      >
        <Select options={QuizQuestionTypes} />
      </Form.Item>
      <Form.Item
        name={[keyName, "title"]}
        rules={[{ required: true }]}
        label="Title"
      >
        <Input />
      </Form.Item>
      <Form.Item name={[keyName, "img"]} label="Image">
        <UploadURLValue />
      </Form.Item>
      <Form.Item label="Answers">
        <Form.List name={[keyName, "options"]}>
          {(fields, { add, remove }) => (
            <>
              <Row gutter={16}>
                {fields.map((field) => {
                  return (
                    <Col span={24} key={`q-${field.name}`}>
                      <OptionItem
                        keyName={field.name}
                        removeFunc={remove}
                        form={form}
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={24}>
                  <Button
                    style={{ backgroundColor: "#52c41a" }}
                    type="primary"
                    onClick={() => add()}
                    block
                    icon={<Icons.PlusOutlined />}
                  >
                    Add Answer
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Card>
  );
};
