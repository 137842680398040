export const buildTreeData = (data: any) => {
  const res: any[] = [];
  if (!data) {
    return res;
  }
  data.forEach((item: any) => {
    const t: any = { title: item.name, value: item.id };
    if (item.children) {
      t.children = buildTreeData(item.children);
    }
    res.push(t);
  });
  return res;
};

export const groupApis = (data: any) => {
  const res: any[] = [];
  if (!data) {
    return res;
  }
  let m: any = {};
  data.forEach((item: any) => {
    let g = m[item.group] ?? {};
    m[item.group] = g;
    g.title = item.group;
    g.value = item.group;
    if (!g.children) {
      g.children = [];
    }
    g.children.push({
      title: `${item.desc}([${item.method}] ${item.path})`,
      value: `${item.method}||${item.path}`,
    });
  });
  for (const key in m) {
    if (Object.prototype.hasOwnProperty.call(m, key)) {
      const element = m[key];
      res.push(element);
    }
  }
  return res;
};
