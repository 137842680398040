import { ResourceProps } from "@pankod/refine-core";
import backendResources from "resources/backend";
import contentTypeResource from "resources/content-type";
import devToolsResources from "resources/devtools";
import systemResources from "resources/system";

const resources: ResourceProps[] = [];
resources.push(...systemResources);
resources.push(...backendResources);
if (process.env.NODE_ENV !== "production") {
  resources.push(...devToolsResources);
}
resources.push(...contentTypeResource);
export default resources;
