import { Create, Form, Input, useForm } from "@pankod/refine-antd"

export const PetCardsCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Time" name="time">
          <Input />
        </Form.Item>
        <Form.Item label="Attachments" name="attachments">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}