import { DateField, DeleteButton, EditButton, ImageField, List, Space, Table, TextField, useTable } from "@pankod/refine-antd"
import { getThumbUrl } from "utils/goapi"

export const UsersList: React.FC = () => {
  const { tableProps } = useTable({
    metaData: {
      fields: ['avatar']
    }
  })
  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="avatar"
            title="Avatar"
            render={(value) => {
              if (!value || value.length <= 0) {
                return <TextField value="No Avatar" />
              }
              return (
                <ImageField value={getThumbUrl(value.key)} width={72} />
              )
            }}
          />
          <Table.Column title="Name" render={(_text, record: any) => {
            return (
              <TextField value={`${record.first_name} ${record.last_name}`} />
            )
          }} />
          <Table.Column dataIndex="email" title="Email" />
          <Table.Column dataIndex="phone" title="Phone" />
          <Table.Column dataIndex="is_activated" title="IsActivated" />
          <Table.Column dataIndex="last_login" title="LastLogin"
            render={(value) => {
              if (value === '0001-01-01T00:00:00Z') {
                return <TextField value="Never login" />
              }
              return <DateField value={value} format="LLL" />
            }}
          />
          <Table.Column dataIndex="last_ip_address" title="LastIpAddress" />
          <Table.Column dataIndex="created_at" title="CreatedAt"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column dataIndex="updated_at" title="UpdatedAt"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }
            }
          />
        </Table>
      </List>
    </>
  )
}
