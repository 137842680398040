import {
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

export const BackendUsersEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    metaData: {
      fields: ["roles", "avatar"],
    },
  });
  const { selectProps: roleSeleProps } = useSelect({
    resource: "backend-user-roles",
    optionValue: "id",
    optionLabel: "name",
  });
  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="UserName" name="login">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="First Name" name="first_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" name="last_name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="is_activated"
              label="Is Activiated"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_superuser"
              label="Is SuperUser"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="roles"
          label="Roles"
          getValueProps={(values?: { id: number }[] | number[]) => {
            return {
              value: values?.map((c) => (typeof c === "object" ? c.id : c)),
            };
          }}
        >
          <Select mode="multiple" {...roleSeleProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
