import { TextField, ImageField } from "@pankod/refine-antd"
import { getThumbUrl } from "utils/goapi"

export const ListThumb: React.FC<{ value?: { key: string } }> = ({ value }) => {
  if (!value) {
    return <TextField value="No Avatar" />
  }
  return (
    <ImageField value={getThumbUrl(value.key)} width={72} />
  )
}
