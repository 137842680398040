import { Create, Form, Icons, Input, Upload, useForm } from "@pankod/refine-antd"
import { API_URL, TOKEN_KEY } from "consts";
import { mediaUploadMapper, getUploadValueProps } from "utils/goapi";

export const UsersCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        form={form}
        onFinish={(values) => {
          return formProps.onFinish?.({
            ...mediaUploadMapper(values),
          });
        }}
      >
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="First Name" name="first_name">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input />
        </Form.Item>
        <Form.Item label="Avatar">
          <Form.Item
            name="avatar"
            valuePropName="fileList"
            getValueProps={(data) => getUploadValueProps(data, API_URL)}
            getValueFromEvent={(args: any) => {
              args.multiple = false
              return args
            }}
            noStyle
          >
            <Upload
              accept="image/*"
              name="files"
              action={`${API_URL}/upload-files`}
              maxCount={1}
              headers={{
                Authorization: `Bearer ${localStorage.getItem(
                  TOKEN_KEY,
                )}`,
              }}
              listType="picture-card"
            >
              <div>
                <Icons.PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
        </Form.Item>
      </Form>
    </Create>
  )
}
