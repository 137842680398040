import { Form, FormProps, Modal, ModalProps } from "@pankod/refine-antd";

interface RelationFormModalProps {
  modalProps?: ModalProps;
  formProps?: FormProps;
  formItemNodes?: React.ReactNode
}
export const RelationFormModal: React.FC<RelationFormModalProps> = ({ modalProps, formProps, formItemNodes }) => {
  return (
    <Modal
      {...modalProps}
    >
      <Form {...formProps}>
        {formItemNodes}
      </Form>
    </Modal>
  )
};
