import { Create, Icons, message, Upload } from "@pankod/refine-antd"
import { useNavigation } from "@pankod/refine-core"
import { API_URL, TOKEN_KEY } from "consts"

export const UploadFilesCreate: React.FC = () => {
  const { goBack } = useNavigation()
  const saveProps = {
    onClick: () => {
      goBack()
    }
  }
  const onChange = (info: any) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      goBack()
    }
  }
  return (
    <Create
      saveButtonProps={saveProps}
    >
      <Upload.Dragger
        accept="image/*,video/*"
        name="files"
        action={`${API_URL}/upload-files`}
        headers={{
          Authorization: `Bearer ${localStorage.getItem(
            TOKEN_KEY,
          )}`,
        }}
        listType="picture"
        multiple
        onChange={onChange}
      >
        <p className="ant-upload-drag-icon">
          <Icons.InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          band files
        </p>
      </Upload.Dragger>
    </Create>
  )
}
