import axios, {
  AxiosHeaders,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import {
  DataProvider as IDataProvider,
  HttpError,
  CrudFilters,
  CrudSorting,
  CrudOperators,
  LogicalFilter,
} from "@pankod/refine-core";
import { stringify, parse } from "qs";
import { TOKEN_KEY, USER_KEY } from "./consts";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY);
  (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    if (customError.statusCode && customError.statusCode === 401) {
      setTimeout(() => {
        logout();
        window.location.assign("/login");
      }, 5000);
    }

    return Promise.reject(customError);
  }
);

const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
};

const mapOperator = (operator: CrudOperators) => {
  switch (operator) {
    case "startswith":
      return "startsWith";
    case "endswith":
      return "endsWith";
    case "nin":
      return "notIn";
    case "ncontains":
      return "notContains";
    case "containss":
      return "containsi";
    case "ncontainss":
      return "notContainsi";
  }

  return operator;
};

const generateSort = (sort?: CrudSorting) => {
  const _sort: string[] = [];

  if (sort) {
    sort.map((item) => {
      if (item.order) {
        _sort.push(`${item.field},${item.order}`);
      }
      return item;
    });
  }

  return _sort;
};

const generateFilter = (filters?: CrudFilters) => {
  let rawQuery = "";

  if (filters) {
    filters.map((filter) => {
      if (
        filter.operator !== "or" &&
        filter.operator !== "and" &&
        "field" in filter
      ) {
        const { field, operator, value } = filter;

        const mapedOperator = mapOperator(operator);

        if (Array.isArray(value)) {
          value.map((val, index) => {
            rawQuery += `&filters[${field}][$${mapedOperator}][${index}]=${val}`;
            return val;
          });
        } else {
          rawQuery += `&filters[${field}][$${mapedOperator}]=${value}`;
        }
      } else {
        const { value } = filter;

        value.map((item, index) => {
          const { field, operator, value } = item as LogicalFilter;

          const mapedOperator = mapOperator(operator);

          rawQuery += `&filters[$${filter.operator}][${index}][${field}][$${mapedOperator}]=${value}`;
          return item;
        });
      }
      return filter;
    });
  }

  const parsedQuery = parse(rawQuery);
  const queryFilters = stringify(parsedQuery, { encodeValuesOnly: true });

  return queryFilters;
};

const normalizeData = (data: any): any => {
  return data.data;
};

export const DataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Required<IDataProvider> => ({
  getList: async ({
    resource,
    hasPagination = true,
    pagination = { current: 1, pageSize: 10 },
    filters,
    sort,
    metaData,
  }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10 } = pagination ?? {};

    const fields = metaData?.fields;
    const queries = metaData?.queries;

    const quertSorters = generateSort(sort);
    const queryFilters = generateFilter(filters);

    const query = {
      ...(hasPagination
        ? {
            page: current,
            pageSize: pageSize,
          }
        : {}),
      fields: fields && fields.length > 0 ? fields.join(",") : undefined,
      sort: quertSorters.length > 0 ? quertSorters.join(",") : undefined,
      ...queries,
    };

    const { data } = await httpClient.get(
      `${url}?${stringify(query, {
        encodeValuesOnly: true,
      })}&${queryFilters}`
    );

    return {
      data: normalizeData(data),
      // added to support pagination on client side when using endpoints that provide only data (see https://github.com/refinedev/refine/issues/2028)
      total: data.meta?.total || normalizeData(data)?.length,
    };
  },

  getMany: async ({ resource, ids, metaData }) => {
    const url = `${apiUrl}/${resource}`;

    const locale = metaData?.locale;
    const fields = metaData?.fields;
    const populate = metaData?.populate;
    const publicationState = metaData?.publicationState;

    const queryFilters = generateFilter([
      {
        field: "id",
        operator: "in",
        value: ids,
      },
    ]);

    const query = {
      locale,
      fields,
      populate,
      publicationState,
      "pagination[pageSize]": ids.length,
    };

    const { data } = await httpClient.get(
      `${url}?${stringify(query, {
        encodeValuesOnly: true,
      })}&${queryFilters}`
    );

    return {
      data: normalizeData(data),
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    let dataVariables: any = { ...variables };

    if (resource === "users") {
      dataVariables = variables;
    }

    const { data } = await httpClient.post(url, dataVariables);
    return {
      data,
    };
  },

  update: async ({ resource, id, variables, metaData }) => {
    const url = `${apiUrl}/${resource}/${id}`;
    const query = metaData?.queries;

    let dataVariables: any = { ...variables };

    if (resource === "users") {
      dataVariables = variables;
    }

    const { data } = await httpClient.put(
      `${url}?${stringify(query, { encodeValuesOnly: true })}`,
      dataVariables
    );
    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const url = `${apiUrl}/${resource}/${id}`;

        let dataVariables: any = { ...variables };

        if (resource === "users") {
          dataVariables = variables;
        }
        const { data } = await httpClient.put(url, dataVariables);
        return data;
      })
    );

    return { data: response };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, {
          ...param,
        });
        return data;
      })
    );

    return { data: response };
  },

  getOne: async ({ resource, id, metaData }) => {
    const locale = metaData?.locale;
    const fields = metaData?.fields;
    const populate = metaData?.populate;

    const query = {
      locale,
      fields,
      populate,
    };

    const url = `${apiUrl}/${resource}/${id}?${stringify(query, {
      encode: false,
      arrayFormat: "repeat",
    })}`;

    const { data } = await httpClient.get(url);

    return {
      data: normalizeData(data),
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
        return data;
      })
    );
    return { data: response };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const sortQuery = generateSort(sort);
      if (sortQuery.length > 0) {
        requestUrl = `${requestUrl}&${stringify({
          sort: sortQuery.join(","),
        })}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${filterQuery}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...(headers as any),
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
