import { UsersList, UsersCreate, UsersEdit } from "pages/users";
import { PetsList, PetsCreate, PetsEdit } from "pages/pets";
import { PetCertsList, PetCertsCreate, PetCertsEdit } from "pages/pet-certs";
import { PetCardsList, PetCardsCreate, PetCardsEdit } from "pages/pet-cards";
import {
  PetMedicalsList,
  PetMedicalsCreate,
  PetMedicalsEdit,
} from "pages/pet-medicals";
import {
  PetVaccinesList,
  PetVaccinesCreate,
  PetVaccinesEdit,
} from "pages/pet-vaccines";
import { AuthorsList, AuthorsCreate, AuthorsEdit } from "pages/authors";
import {
  QuizCategoriesList,
  QuizCategoriesCreate,
  QuizCategoriesEdit,
} from "pages/quiz-categories";
import { QuizzesList, QuizzesCreate, QuizzesEdit } from "pages/quizzes";
import { BlogsList, BlogsCreate, BlogsEdit } from "pages/blogs";
import {
  BlogCategoriesList,
  BlogCategoriesCreate,
  BlogCategoriesEdit,
} from "pages/blog-categories";
import { Icons } from "@pankod/refine-antd";

const contentTypeResource = [
  {
    name: "users",
    list: UsersList,
    create: UsersCreate,
    edit: UsersEdit,
  },
  {
    name: "pets-manager",
    options: {
      label: "Pets",
    },
    icon: <Icons.QqOutlined />,
  },
  {
    parentName: "pets-manager",
    name: "pets",
    list: PetsList,
    create: PetsCreate,
    edit: PetsEdit,
  },
  {
    parentName: "pets-manager",
    name: "pet-medicals",
    list: PetMedicalsList,
    create: PetMedicalsCreate,
    edit: PetMedicalsEdit,
  },
  {
    parentName: "pets-manager",
    name: "pet-vaccines",
    list: PetVaccinesList,
    create: PetVaccinesCreate,
    edit: PetVaccinesEdit,
  },
  {
    parentName: "pets-manager",
    name: "pet-certs",
    list: PetCertsList,
    create: PetCertsCreate,
    edit: PetCertsEdit,
  },
  {
    parentName: "pets-manager",
    name: "pet-cards",
    list: PetCardsList,
    create: PetCardsCreate,
    edit: PetCardsEdit,
  },
  {
    name: "authors",
    list: AuthorsList,
    create: AuthorsCreate,
    edit: AuthorsEdit,
    icon: <Icons.TeamOutlined />,
  },
  {
    name: "quiz-manager",
    options: {
      label: "Quizzes",
    },
    icon: <Icons.QuestionCircleOutlined />,
  },
  {
    parentName: "quiz-manager",
    name: "quiz-categories",
    list: QuizCategoriesList,
    create: QuizCategoriesCreate,
    edit: QuizCategoriesEdit,
  },
  {
    parentName: "quiz-manager",
    name: "quizzes",
    list: QuizzesList,
    create: QuizzesCreate,
    edit: QuizzesEdit,
  },
  {
    name: "blog-manager",
    options: {
      label: "Blogs",
    },
    icon: <Icons.ContainerOutlined />,
  },
  {
    parentName: "blog-manager",
    name: "blogs",
    list: BlogsList,
    create: BlogsCreate,
    edit: BlogsEdit,
  },
  {
    parentName: "blog-manager",
    name: "blog-categories",
    list: BlogCategoriesList,
    create: BlogCategoriesCreate,
    edit: BlogCategoriesEdit,
  },
];

export default contentTypeResource;
