import { Icons } from "@pankod/refine-antd";

import {
  DictionariesList,
  DictionariesCreate,
  DictionariesEdit,
} from "pages/dictionaries";
const systemResources = [
  {
    name: "system",
    icon: <Icons.GoldOutlined />
  },
  {
    parentName: "system",
    name: "dictionaries",
    list: DictionariesList,
    create: DictionariesCreate,
    edit: DictionariesEdit,
    icon: <Icons.BookOutlined />
  },
];
export default systemResources;
