import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { ListThumb } from "components/list/ListThumb";

export const AuthorsList: React.FC = () => {
  const { tableProps } = useTable({
    metaData: {
      fields: ["avatar"],
    },
  });
  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="avatar"
            title="Avatar"
            render={(value) => {
              return <ListThumb value={value} />;
            }}
          />
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column dataIndex="slug" title="Slug" />
          <Table.Column
            dataIndex="created_at"
            title="CreatedAt"
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            dataIndex="updated_at"
            title="UpdatedAt"
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton size="small" hideText recordItemId={record.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
