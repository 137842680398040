import { Editor } from "@tinymce/tinymce-react";
import { API_URL, ASSETS_URL, TOKEN_KEY } from "consts";
import { useRef } from "react";

interface TinyMCEProps {
  value?: any;
  onChange?: (value?: any) => void;
}
export const TinyMCE: React.FC<TinyMCEProps> = ({ value, onChange }) => {
  const tinymceRef = useRef<any>(null);
  const uploadHandler = async (blobInfo: any, progress: any) =>
    new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${API_URL}/upload-files`);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem(TOKEN_KEY)}`
      );

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);
        if (!json || !json.data) {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }
        const data = json.data;
        const url = `${ASSETS_URL}/${data.key}`;
        resolve(url);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("files", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });
  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
      // apiKey={"qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"}
      value={value}
      onEditorChange={(e) => {
        onChange?.(e);
      }}
      onInit={(e, editor) => {
        tinymceRef.current = editor;
      }}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "code",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic forecolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "image | " +
          "removeformat | help | code",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        file_picker_callback(callback, value, meta) {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", (e: any) => {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener("load", () => {
              const id = "blobid" + new Date().getTime();
              console.log(tinymceRef.current);
              const blobCache = tinymceRef.current.editorUpload.blobCache;
              const base64 = (reader.result as string).split(",")[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              callback(blobInfo.blobUri(), { title: file.name });
            });
            reader.readAsDataURL(file);
          });

          input.click();
        },
        automatic_uploads: true,
        images_upload_handler: uploadHandler,
      }}
    />
  );
};
