import { ASSETS_URL } from "../consts";
export const getUploadValueProps = (data: any, url: string) => {
  if (!data) {
    return { fileList: [] };
  }
  if (
    typeof data === "object" &&
    !Array.isArray(data) &&
    data !== null &&
    data.key
  ) {
    data.url = getThumbUrl(data.key);
    return { fileList: [data] };
  }
  if (Array.isArray(data)) {
    const res = data.map((item: any) => {
      item.url = getThumbUrl(item.key);
      return item;
    });
    return { fileList: res };
  }
  return data;
};

export const getUploadValueFromEvent = (args: any, multiple?: boolean) => {
  if (multiple) {
    const data: any = [];
    const { fileList } = args;
    for (const file of fileList) {
      if (file.response && file.response.data) {
        const f = file.response.data;
        if (f.key) {
          f.url = getThumbUrl(f.key);
        }
        data.push(file.response.data);
      } else {
        data.push(file);
      }
    }
    return data;
  }
  // Single File
  const file = args?.file;
  if (file && file.response && file.response.data) {
    const f = file.response.data;
    if (f.key) {
      f.url = getThumbUrl(f.key);
    }
    return f;
  }
  return args;
};

export const mediaUploadMapper = (params: any) => {
  Object.keys(params).map((item) => {
    if (params[item]) {
      const param = params[item].fileList;
      const isMediaField = Array.isArray(param);
      if (isMediaField) {
        const multiple = params[item].multiple;
        if (multiple) {
          // Multiple upload
          const ids = [];
          for (const item of param) {
            if (item.response.data) {
              ids.push(item.response.data.id);
            } else {
              ids.push(item.uid);
            }
          }
          params[item] = ids;
        } else {
          // Single upload
          const file = params[item].file;
          if (file && file.response && file.response.data) {
            params[item] = { id: file.response.data.id };
          }
        }
      }
    }
    return null;
  });
  return params;
};

export const getThumbUrl = (key: string) => {
  return `${ASSETS_URL}/${key}`;
};
