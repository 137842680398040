import { AccessControlProvider } from "@pankod/refine-core";
import { MENU_KEY, USER_KEY } from "consts";
import { IMenu, IUser } from "types/user";

export const accessProvider: AccessControlProvider = {
  can({ resource, action, params }) {
    const user: IUser = JSON.parse(localStorage.getItem(USER_KEY) || "{}");
    const menus: IMenu[] = JSON.parse(localStorage.getItem(MENU_KEY) || "{}");
    if (user.is_superuser) {
      return Promise.resolve({ can: true });
    }
    if (!menus) {
      return Promise.resolve({ can: false });
    }
    const menu = menus.find((m) => m.path === resource);
    if (menu) {
      return Promise.resolve({ can: true });
    }
    return Promise.resolve({ can: false });
  },
};
