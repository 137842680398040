import { Edit, Form, Input, useForm } from "@pankod/refine-antd"

export const UploadFilesEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, queryResult } = useForm()
  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Key" name="key">
            <Input />
          </Form.Item>
          <Form.Item label="Tag" name="tag">
            <Input />
          </Form.Item>
          <Form.Item label="Url" name="url">
            <Input />
          </Form.Item>
      </Form>
    </Edit>
  )
}