import { Col, Create, Form, Input, Row, Select, Switch, useForm } from "@pankod/refine-antd"
import { typeOptions } from "./options"

export const DictionariesCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Desc" name="desc">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Type" name="type">
              <Select options={typeOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" name="status" valuePropName="checked" initialValue={true}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
