import { Col, Row, Slider } from "@pankod/refine-antd";

interface AssociateResultPorps {
  results?: { id: number; name: string; uuid?: string }[];
  value?: any[];
  onChange?: any;
}
export const AssociateResult: React.FC<AssociateResultPorps> = ({
  results,
  value,
  onChange,
}) => {
  const onSliderChange = (id: number, v: number) => {
    let res = [...(value || [])];
    if (results) {
      const rMap: any = results.reduce((map: any, obj: any) => {
        map[obj.id] = obj;
        return map;
      }, {});
      res = res.filter((v: any) => rMap[v.id]);
    }
    const item = res.find((v: any) => v.id === id);
    if (!item) {
      res.push({ id: id, score: v });
    } else {
      item.score = v;
    }
    onChange?.([...res]);
  };
  const getDefaultValueFromID = (id: number) => {
    if (!value) {
      return 0;
    }
    const item = value.find((v: any) => v.id === id);
    if (item) {
      return item.score;
    }
    return 0;
  };
  return (
    <>
      {results?.map((r) => {
        if (!r) {
          return <></>;
        }
        return (
          <Row key={r.name} align="middle">
            <Col span={24}>{r.name}</Col>
            <Col span={24}>
              <Slider
                max={10}
                marks={{ 0: 0, 10: 10 }}
                defaultValue={getDefaultValueFromID(r.id)}
                onChange={(v: number) => onSliderChange(r.id, v)}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};
