import { Create, Form, Input, useForm } from "@pankod/refine-antd"

export const BackendUsersCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="UserName" name="login">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input />
        </Form.Item>
        <Form.Item label="First Name" name="first_name">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
