import { DateField, DeleteButton, EditButton, List, Space, Table, TextField, useTable } from "@pankod/refine-antd"
import { StatusTag } from "components/tag/StatusTag"
import { getOptionLable } from "./options"

export const DictionariesList: React.FC = () => {
  const { tableProps } = useTable({})
  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column
            dataIndex="type"
            title="Type"
            render={(value, record): React.ReactNode => {
              return <TextField value={getOptionLable(value) ?? "Unknown"} />
            }}
          />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value, record): React.ReactNode => {
              return <StatusTag value={value} />
            }}
          />
          <Table.Column dataIndex="desc" title="Desc" />
          <Table.Column dataIndex="created_at" title="CreatedAt"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column dataIndex="updated_at" title="UpdatedAt"
            render={(value) => (
              <DateField value={value} format="LLL" />
            )}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }
            }
          />
        </Table>
      </List>
    </>
  )
}
