import { Col, DatePicker, Edit, Form, Input, InputNumber, Row, useForm } from "@pankod/refine-antd"
import { Uploader } from "components/uploader"
import dayjs from "dayjs"

export const PetMedicalsEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    metaData: {
      fields: ['attachments']
    }
  })
  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Pet" name="pet_id">
          <InputNumber disabled />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Time"
              name="time"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Place" name="place">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Notes" name="notes">
          <Input />
        </Form.Item>
        <Form.Item label="Attachments">
          <Uploader name="attachments" upProps={{ multiple: true }} />
        </Form.Item>
      </Form>
    </Edit>
  )
}
