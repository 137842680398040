import { Edit, Form, Input, useForm } from "@pankod/refine-antd";

export const BlogCategoriesEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm();
  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Slug" name="slug">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
