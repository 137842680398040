import { Create, Form, Input, useForm } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { TreeFormItem } from "components/TreeFormItem";
import { buildTreeData, groupApis } from "utils/utils";

export const BackendUserRolesCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    metaData: {
      fields: ["menus", "apis"],
    },
  });
  // Get all menus
  const menus = useList({
    resource: "backend-menus",
    metaData: {
      queries: {
        tree: true,
      },
    },
    config: {
      pagination: { pageSize: 5000 },
    },
  });
  const menusTree: any = buildTreeData(menus?.data?.data);

  // Get menus belongs to this role
  // Get all apis
  const apis = useList({
    resource: "backend-apis",
    config: {
      pagination: { pageSize: 5000 },
    },
  });
  const apisTree: any = groupApis(apis?.data?.data);

  const treeProps = {
    fieldNames: {
      title: "title",
      key: "value",
      children: "children",
    },
    defaultExpandAll: false,
  };
  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Key" name="key">
          <Input />
        </Form.Item>
        <Form.Item
          label="Menus"
          name="menus"
          getValueProps={(menus?: { id: number }[]) => {
            return { value: menus?.map((m) => m.id) }; // Set selected data
          }}
          getValueFromEvent={(args) => {
            return args?.map((id: any) => ({ id }));
          }}
        >
          <TreeFormItem data={menusTree} treeProps={treeProps} />
        </Form.Item>
        <Form.Item
          label="APIs"
          name="apis"
          getValueProps={(apis?: { path: string; method: string }[]) => {
            return { value: apis?.map((m) => `${m.method}||${m.path}`) }; // Set selected data
          }}
          getValueFromEvent={(args: string[]) => {
            const res: { path: string; method: string }[] = [];
            args.forEach((v) => {
              const api: string[] = v.split("||");
              if (api.length === 2) {
                res.push({ path: api[1], method: api[0] });
              }
            });
            return res;
          }}
        >
          <TreeFormItem data={apisTree} treeProps={treeProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
