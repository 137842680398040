import { Create, Form, Input, useForm } from "@pankod/refine-antd"

export const BackendApisCreate: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm()
  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Form.Item label="Path" name="path">
          <Input />
        </Form.Item>
        <Form.Item label="Method" name="method">
          <Input />
        </Form.Item>
        <Form.Item label="Group" name="group">
          <Input />
        </Form.Item>
        <Form.Item label="Desc" name="desc">
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
