import {
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";

export const BackendUsersList: React.FC = () => {
  const { tableProps } = useTable({});
  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="login" title="Username" />
          <Table.Column dataIndex="email" title="Email" />
          <Table.Column
            dataIndex="is_superuser"
            title="Is Super"
            render={(v) => {
              return <TextField value={v ? "True" : "False"} />;
            }}
          />
          <Table.Column dataIndex="created_at" title="CreatedAt" />
          <Table.Column dataIndex="updated_at" title="UpdatedAt" />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton size="small" hideText recordItemId={record.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
