import {
  Button,
  DeleteButton,
  EditButton,
  Icons,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";

export const BackendApisList: React.FC = () => {
  const { tableProps } = useTable({});
  const { mutate: syncAPIMutate, isLoading: syncLoading } = useCreate();
  const onSync = () => {
    syncAPIMutate({
      resource: "backend-apis/sync",
      values: {},
      successNotification(data, values, resource) {
        return { type: "success", message: `Successfully sync` };
      },
    });
  };
  return (
    <>
      <List
        title="APIs"
        headerButtons={({ defaultButtons }) => (
          <>
            {defaultButtons}
            <Button
              loading={syncLoading}
              onClick={onSync}
              type="primary"
              icon={<Icons.SyncOutlined />}
            >
              Sync Admin API
            </Button>
          </>
        )}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="path" title="Path" />
          <Table.Column dataIndex="method" title="Method" />
          <Table.Column dataIndex="group" title="Group" />
          <Table.Column dataIndex="desc" title="Desc" />
          <Table.Column dataIndex="created_at" title="CreatedAt" />
          <Table.Column dataIndex="updated_at" title="UpdatedAt" />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton size="small" hideText recordItemId={record.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
