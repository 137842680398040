export const typeOptions = [
  {
    label: "Integer",
    value: "integer",
  },
  {
    label: "Boolean",
    value: "bool",
  },
  {
    label: "String",
    value: "string",
  },
];

export const getOptionLable = (v: string): string => {
  const op = typeOptions.find((item) => item.value === v);
  return op ? op.label : "";
};

export const statusOptions = [
  {
    label: "Enable",
    value: true,
  },
  {
    label: "Disable",
    value: false,
  },
];

export const getStatusLable = (v: boolean): string => {
  const op = statusOptions.find((item) => item.value === v);
  return op ? op.label : "";
};
