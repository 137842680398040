import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import { ListThumb } from "components/list/ListThumb";

export const BlogsList: React.FC = () => {
  const { tableProps } = useTable({
    metaData: {
      fields: ["thumb"],
    },
  });
  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="thumb"
            title="Thumb"
            render={(value) => {
              return <ListThumb value={value} />;
            }}
          />
          <Table.Column dataIndex="title" title="Title" />
          <Table.Column dataIndex="slug" title="Slug" />
          <Table.Column
            dataIndex="published"
            title="Published"
            render={(value) => {
              return <TextField value={value ? "show" : "hide"} />;
            }}
          />
          <Table.Column
            dataIndex="published_at"
            title="PublishedAt"
            render={(value, record: any) => {
              if (record?.published_at) {
                return <DateField value={value} format="LLL" />;
              }
              return <TextField value="Empty" />;
            }}
          />
          <Table.Column
            dataIndex="created_at"
            title="CreatedAt"
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            dataIndex="updated_at"
            title="UpdatedAt"
            render={(value) => <DateField value={value} format="LLL" />}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton size="small" hideText recordItemId={record.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
