import { Tree, TreeProps } from "@pankod/refine-antd";

interface TreeFormItemProps {
  data?: any;
  value?: any;
  onChange?: (value: any) => void;
  treeProps?: any
}
export const TreeFormItem: React.FC<TreeFormItemProps> = ({ data, value, onChange, treeProps }) => {
  const onChecked: TreeProps['onCheck'] = (checkedKeys, info) => {
    onChange?.(checkedKeys)
  }
  return (
    <>
      {data && <Tree
        checkable
        selectable={false}
        defaultExpandAll={true}
        treeData={data as any}
        onCheck={onChecked}
        defaultCheckedKeys={value}
        {...treeProps}
      />
      }
    </>
  )
}
