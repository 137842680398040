import {
  Button,
  Col,
  DatePicker,
  Divider,
  Edit,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { QuestionItem, ResultItem } from "components/quiz";
import { Uploader } from "components/uploader";
import { QuizTypes } from "data/quizType";
import dayjs from "dayjs";

export const QuizzesEdit: React.FC = () => {
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    metaData: {
      fields: ["thumb", "author.avatar", "categories"],
    },
  });
  const { selectProps: catSelectProps } = useSelect({
    resource: "quiz-categories",
    optionLabel: "label",
    optionValue: "id",
  });
  const { selectProps: authorSelectProps } = useSelect({
    resource: "authors",
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" form={form}>
        <Divider orientation="left">Basic</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
              <Select options={QuizTypes} />
            </Form.Item>
            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Thumb">
              <Uploader name="thumb" />
            </Form.Item>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  label="Published"
                  name="published"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="PublishedAt"
                  name="published_at"
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : "",
                  })}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="Author"
              name="author"
              rules={[{ required: true }]}
              getValueProps={(value) => {
                return { value: value?.id };
              }}
            >
              <Select {...authorSelectProps} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Categories"
              name="categories"
              rules={[{ required: true }]}
              getValueProps={(values?: { id: number }[]) => {
                return { value: values?.map((c) => c.id) };
              }}
              getValueFromEvent={(args: any[]) => {
                return args.map((item) => ({
                  id: item,
                }));
              }}
            >
              <Select mode="multiple" {...catSelectProps} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Desc" name="desc">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Results</Divider>
        <Form.Item label="Results">
          <Form.List name="results">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={16} style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      block
                      icon={<Icons.PlusOutlined />}
                    >
                      Add Result
                    </Button>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {fields.map((field) => (
                    <Col xxl={8} xl={12} md={12} sm={24} key={field.name}>
                      <ResultItem keyName={field.name} removeFunc={remove} />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Divider orientation="left">Questions</Divider>
        <Form.Item label="Questions">
          <Form.List name="questions">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={16} style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      block
                      icon={<Icons.PlusOutlined />}
                    >
                      Add Question
                    </Button>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {fields.map((field) => (
                    <Col key={field.name} xxl={8} xl={12} md={12} sm={24}>
                      <QuestionItem
                        form={form}
                        keyName={field.name}
                        removeFunc={remove}
                      />
                    </Col>
                  ))}
                </Row>
                {fields.length > 0 && (
                  <Row
                    gutter={16}
                    style={{ marginBottom: "1rem" }}
                    justify="end"
                  >
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<Icons.PlusOutlined />}
                      >
                        Add Question
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Edit>
  );
};
