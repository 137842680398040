import {
  Button,
  Card,
  Form,
  Icons,
  Input,
  InputNumber,
} from "@pankod/refine-antd";
import { UploadURLValue } from "components/uploader";

interface ResultItemProps {
  removeFunc?: any;
  keyName?: any;
}

export const ResultItem: React.FC<ResultItemProps> = ({
  removeFunc,
  keyName,
}) => {
  return (
    <Card
      title={`Result of ${keyName + 1}`}
      extra={
        <Button
          danger
          onClick={() => removeFunc?.(keyName)}
          icon={<Icons.CloseOutlined />}
        ></Button>
      }
    >
      <Form.Item
        name={[keyName, "id"]}
        rules={[{ required: true }]}
        label="ID"
        initialValue={keyName}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name={[keyName, "name"]}
        rules={[{ required: true }]}
        label="Title"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={[keyName, "desc"]}
        rules={[{ required: true }]}
        label="Desc"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item name={[keyName, "img"]} rules={[]} label="Image">
        <UploadURLValue />
      </Form.Item>
      <Form.Item name={[keyName, "max"]} label="Max Score" initialValue={0}>
        <InputNumber />
      </Form.Item>
    </Card>
  );
};
