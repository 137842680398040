import { DeleteButton, EditButton, ImageField, List, Space, Table, TextField, useTable } from "@pankod/refine-antd"
import { getThumbUrl } from "utils/goapi"

export const UploadFilesList: React.FC = () => {
  const { tableProps } = useTable({})
  const isImg = (key: string) => {
    return true
  }
  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="key"
            title="Thumb"
            render={(value) => {
              if (isImg(value)) {
                return <ImageField value={getThumbUrl(value)} width={72} />
              }
              return <TextField value="TODO" />
            }}
          />
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column dataIndex="tag" title="Tag" />
          <Table.Column dataIndex="created_at" title="CreatedAt" />
          <Table.Column dataIndex="updated_at" title="UpdatedAt" />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_text, record: any): React.ReactNode => {
              return (
                <Space>
                  <EditButton
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }
            }
          />
        </Table>
      </List>
    </>
  )
}
